import React, {useState, useEffect} from 'react';
import Icon from '../../components/Icon/Index';
import Rating from 'react-rating';
import { wrap } from 'lodash';
import { Hidden } from '@material-ui/core';
import { toast } from 'react-toastify';
const Star = (props) => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FBBC06"
      fillRule="nonzero"
      d="M18.3 22.913c-.382 0-.76-.115-1.08-.34l-5.216-3.67-5.218 3.67a1.883 1.883 0 0 1-2.145.007c-.641-.441-.907-1.228-.656-1.944l2.05-5.86L.76 11.181c-.644-.44-.915-1.223-.671-1.94.243-.713.94-1.195 1.727-1.195h.013l6.487.05 1.957-5.89C10.51 1.49 11.212 1 12.002 1c.79 0 1.492.49 1.728 1.207l1.956 5.89 6.484-.05h.017c.783 0 1.485.482 1.723 1.195.246.718-.022 1.5-.667 1.94l-5.273 3.593 2.053 5.861c.248.714-.018 1.501-.654 1.944-.32.222-.696.333-1.07.333z"
    />
  </svg>
);

const StarO = (props) => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#DEDEDE"
      fillRule="nonzero"
      d="M18.3 22.913c-.382 0-.76-.115-1.08-.34l-5.216-3.67-5.218 3.67a1.883 1.883 0 0 1-2.145.007c-.641-.441-.907-1.228-.656-1.944l2.05-5.86L.76 11.181c-.644-.44-.915-1.223-.671-1.94.243-.713.94-1.195 1.727-1.195h.013l6.487.05 1.957-5.89C10.51 1.49 11.212 1 12.002 1c.79 0 1.492.49 1.728 1.207l1.956 5.89 6.484-.05h.017c.783 0 1.485.482 1.723 1.195.246.718-.022 1.5-.667 1.94l-5.273 3.593 2.053 5.861c.248.714-.018 1.501-.654 1.944-.32.222-.696.333-1.07.333z"
    />
  </svg>
);



const CommentModal = ({
  handleSubmit,
  initialRating,
  handleRating,
  errors,
  comment,
  show,
  onToggle,
  product,
  handleFileChange,
}) => {
  const [previewImages, setPreviewImages] = useState([]);
  const [allArrayImages, setAllArrayImages] = useState([]);

  useEffect(() => {
    handleImageSend();
  }, [allArrayImages]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setAllArrayImages(files);
    const maxFiles = 3;
    const maxSize = 10 * 1024 * 1024; // 10 MB

    const customToastComment = (param) => (
        <div className="custom-toast-danger">
          <Icon icon="iInfo" className="mr-3" />
          <span>
          {param}
          </span>
        </div>
      );
  
    if (files.length > maxFiles) {
      toast(customToastComment(`En fazla ${maxFiles} görsel yükleyebilirsiniz.`), {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              closeButton: false,
            });
      return false;
    }
  
    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        toast(customToastComment(`${file.name} dosyası 10MB sınırını aşıyor.`), {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          closeButton: false,
        });
        return false;
      }
      return true;
    });
  
    const previews = [];
    validFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        previews.push(reader.result);
        if (previews.length === validFiles.length) {
          setPreviewImages(previews);
        }
      };
      reader.readAsDataURL(file);
    });
    handleFileChange({ target: { files: allArrayImages } });
  };

  const handleImageSend = () => {
    const files = allArrayImages;
    const maxFiles = 3;
    const maxSize = 10 * 1024 * 1024; // 10 MB

    const customToastComment = (param) => (
        <div className="custom-toast-danger">
          <Icon icon="iInfo" className="mr-3" />
          <span>
          {param}
          </span>
        </div>
      );
  
    if (files.length > maxFiles) {
      toast(customToastComment(`En fazla ${maxFiles} görsel yükleyebilirsiniz.`), {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              closeButton: false,
            });
      return false;
    }
  
    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        toast(customToastComment(`${file.name} dosyası 10MB sınırını aşıyor.`), {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
          closeButton: false,
        });
        return false;
      }
      return true;
    });
  
    const previews = [];
    validFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        previews.push(reader.result);
        if (previews.length === validFiles.length) {
          setPreviewImages(previews);
        }
      };
      reader.readAsDataURL(file);
    });
    handleFileChange({ target: { files: allArrayImages } });
  };


  
  return (
  <div className={`commentModal ${show ? 'd-flex' : 'd-none'}`}>
    <div className="modal-box">
      <div className="modal-header">
        <span />
        <div className="close-btn" onClick={onToggle}>
          <Icon icon="iClose" />
        </div>
        <h5>Değerlendirme Yap</h5>
      </div>
      <div className="modal-body">
        <div className="img-container">
          <img className="img-fluid" src={product.imageUrl} alt="" />
        </div>
        <form className="w-100 mt-2" onSubmit={handleSubmit}>
          <div className="form-group">
            <h6 htmlFor="commentRating" className="mb-3">
              Ürüne kaç puan verirsiniz?
            </h6>
            <h2 className="rating-component">
              <Rating
                emptySymbol={<StarO className="icon" />}
                fullSymbol={<Star className="icon" />}
                initialRating={initialRating}
                onChange={handleRating}
              />
            </h2>
          </div>
          <div className="form-group d-flex">
            <p>Ürünle ilgili görüşlerinizi paylaşın</p>
            {errors.rating ? (
              <>
                <small
                  id="rating_help"
                  className="form-text text-muted col-sm-12"
                />
                <div className="invalid-feedback">Puan bilgisi boş olamaz.</div>
              </>
            ) : null}
            <textarea
              ref={comment}
              name="commentText"
              id="commentText"
              className={`form-control ${errors.comment ? 'is-invalid' : ''}`}
              rows="5"
              placeholder="Ürün"
            />
            {errors.comment ? (
              <>
                <small
                  id="commentText_help"
                  className="form-text text-muted col-sm-12"
                />
                <div className="invalid-feedback">
                  Yorum bilgisi boş olamaz.
                </div>
              </>
            ) : null}
          </div>
          {/* Görsel Yükleme Alanı */}
        <div className="form-group">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => document.getElementById('imageUpload').click()}
          >
            + Görsel Yükle
          </button>
          <input
            id="imageUpload"
            type="file"
            accept="image/*"
            multiple
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
        </div>

        {/* Görsel Önizleme Alanı */}
        <div className="image-preview-container" style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "0.5rem" }}>
        {previewImages.map((src, index) => (
          <div
            key={index}
            className="preview-image"
            style={{
              position: "relative",
              flex: "0 0 auto",
              width: "100px",
              height: "63px",
              overflow: "hidden"
            }}
          >
            {/* Çarpı ikonu */}
            <button
              type="button" 
              onClick={() => {
                setPreviewImages(prev => prev.filter((_, i) => i !== index));
                setAllArrayImages(prev => prev.filter((_, i) => i !== index));
                
              }}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                background: "rgba(0, 0, 0, 0.6)",
                color: "white",
                border: "none",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              ✖
            </button>
            <img
              src={src}
              alt={`preview-${index}`}
              className="img-thumbnail"
              style={{
                width: "100%", // Görseli kapsayıcıya tam sığdırır
                height: "100%", // Görseli kapsayıcıya tam sığdırır
                objectFit: "cover", // Görseli taşmadan kutuya oturtur
                borderRadius: "8px", // Köşeleri yuvarlar
                border: "1px solid #ddd", // İnce bir çerçeve ekler
              }}
            />
          </div>
        ))}
      </div>
          <div className="flex-row d-flex">
            <button type="submit" className="btn comment-button">
              Değerlendir
            </button>
          </div>
          <div className="info-message">
            <Icon icon="iInfo" />
            <p>
              Değerlendirme yapabilmek için bu ürünü satın almış olmalısınız
            </p>
          </div>
        </form>
      </div>
    </div>
    <div
      className="modal-backdrop"
      style={{ opacity: 0.4 }}
      onClick={onToggle}
    ></div>
  </div>
);
};
export default CommentModal;
