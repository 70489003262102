/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import SideBar from '../Product/SideBar';
//import { logos } from "../../variables/General";
import _remove from 'lodash/remove';
import {
  Breadcrumb,
  EditableToolbar,
  EditableItem,
  FormModal,
  Showcase,
  SortProductsModal,
  IconButton,
} from '../../components';
import Select from 'react-select';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from '../../routes/actions/Product';
import * as accountActions from '../../routes/actions/Account';
import { iconHearth, iconHearthOuter } from '../../assets/images/icons';
import {
  orderList,
  favoriteTypes,
  queryStringParameters,
} from '../../services/constants';
import { customStyles, setPrerenderTimeout } from '../../helpers';
import FilterModal from '../Product/FilterModal';
import SortModal from '../Product/SortModal';
import Filters from '../../components/Card/Filters';
import {
  getByName,
  changePage,
  createPage,
  savePage,
  publishPage,
} from '../../routes/actions/Content';
import {
  faTrash,
  faPencilAlt,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import FormSchemas from '../../variables/FormSchemas';
import Icon from '../../components/Icon/Index';

const breadcrumbs = [
  {
    title: 'Anasayfa',
    url: '/',
  },
  {
    title: 'Markalar',
    url: '/markalar',
  },
  {
    title: '-',
    active: true,
  },
];

const menuItems = [
  {
    name: 'edit',
    icon: faPencilAlt,
  },
  {
    name: 'remove',
    icon: faTrash,
    className: 'text-danger',
  },
];

const toolbarItems = [
  {
    name: 'sort',
    title: 'Sıralama',
    icon: faList,
  },
];

const addFilter = (filters, filterArray, id, name) => {
  filterArray.forEach((filter) => {
    if (id === filter) {
      filters.push({
        id,
        apply: true,
        name,
      });
    }
  });
};

const AdvertorialView = ({ item, menuItems, mode, onItemClick, index }) => (
  <div className="card main-page-card overflow-hidden mb-3">
    <EditableItem
      items={menuItems}
      mode={mode}
      onItemClick={(command) => onItemClick(command, item, index)}
    >
      <div className="card-img">
        <Link to={item.target || '/'}>
          {item.src ? (
            <img src={item.src} className="w-100" alt="" />
          ) : (
            <figure className="text-center">735x250</figure>
          )}
        </Link>
      </div>
    </EditableItem>
  </div>
);

class Products extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'live',
      showFilterModal: false,
      showSortModal: false,
      showSortProducts: false,
      show: false,
      filters: [],
      productOrder: orderList[0],
      brand: {},
      formData: {},
      index: -1,
      modified: false,
    };
  }

  change = (page) => {
    this.setState({ modified: true });
    this.props.changePage(page);
  };

  getQueryString(location) {
    const search = queryString.parse(location);

    const query = {
      t: search.t ? search.t.split(',') : [],
      b: search.b ? search.b.split(',') : [],
    };

    return query;
  }

  getMetaData(taxons, brands) {
    const currentMetadata = {
      title: "Tonguç'la çalış, Başarıya alış!",
      description: null,
      heading: null,
      content: null,
      slug: '',
    };

    if (taxons.length || brands.length) {
      let selectedBrand =
        brands.length === 1
          ? Number(brands[0])
          : brands.length === 2 &&
              brands.some((b) => b === '15302') &&
              brands.some((b) => b === '15308')
            ? 15302
            : null;
      const { metadata } = this.props;

      const data = metadata.find(
        (m) =>
          ((selectedBrand === null && !m.brand) || m.brand === selectedBrand) &&
          m.categories.length === taxons.length &&
          m.categories.every((mc) => taxons.some((t) => Number(t) === mc)),
      );

      if (data) {
        currentMetadata.title = data.title;
        currentMetadata.heading = data.heading;
        currentMetadata.description = data.metaDescription;
        currentMetadata.content = data.content;
        currentMetadata.slug = data.slug;
      }
    }

    return currentMetadata;
  }

  getSearchValuesFromQueryString() {
    const querystringValues = queryString.parse(this.props.location.search);
    let taxons = [];
    let brands = [];
    let keyword = '';
    let sort = orderList[0];
    for (let i = 0; i < queryStringParameters.length; i++) {
      let parameter = queryStringParameters[i];
      if (querystringValues[parameter.key]) {
        if (parameter.type === 'taxon') {
          taxons = [...taxons, ...querystringValues[parameter.key].split(',')];
        } else if (parameter.type === 'brand') {
          brands = [...brands, ...querystringValues[parameter.key].split(',')];
        } else if (parameter.type === 'keyword') {
          keyword = querystringValues[parameter.key];
        } else if (parameter.type === 'sort') {
          var sortOption = orderList.find(
            (o) => o.value === querystringValues[parameter.key],
          );
          if (sortOption) {
            sort = sortOption;
          }
        }
      }
    }

    return {
      taxons: Array.from(new Set(taxons)),
      brands: Array.from(new Set(brands)),
      keyword,
      sort,
    };
  }

  getQueryStringAndSlugFromSearchValues(taxons, brands, keyword, sort) {
    let queryStringValues = [];
    if (taxons && taxons.some((x) => !isNaN(x))) {
      let taxonParameters = queryStringParameters.filter(
        (q) => q.type === 'taxon',
      );
      const { taxonomies } = this.props;
      let selectedTaxonomies = taxonomies
        .filter((t) =>
          t.taxons.some(
            (tx) => taxons.includes(tx.id.toString()) || taxons.includes(tx.id),
          ),
        ) // seçili gelen taxonların grubunun seçilmesi
        .map((t) => {
          return {
            id: t.id,
            code: t.code,
            taxons: t.taxons
              .filter(
                (tx) =>
                  taxons.includes(tx.id.toString()) || taxons.includes(tx.id),
              )
              .map((x) => x.id),
          };
        }); // seçili gelen taxonların seçilmesi
      let defaultTaxons = [];
      for (let i = 0; i < selectedTaxonomies.length; i++) {
        const taxonomy = selectedTaxonomies[i];
        var taxonParameter = taxonParameters.find((tp) =>
          taxonomy.code.includes(tp.parentCode),
        );
        if (taxonParameter) {
          queryStringValues.push(
            `${taxonParameter.key}=${taxonomy.taxons.join(',')}`,
          );
        } else {
          defaultTaxons = [...defaultTaxons, ...taxonomy.taxons];
        }
      }
      if (defaultTaxons.length) {
        let taxonParameter = taxonParameters.find((tp) => tp.isDefault);
        queryStringValues.push(
          `${taxonParameter.key}=${defaultTaxons.join(',')}`,
        );
      }
    }

    if (brands && brands.some((x) => !isNaN(x))) {
      let brandParameters =
        queryStringParameters.find((q) => q.type === 'brand' && !q.isDefault) ||
        queryStringParameters.find((q) => q.type === 'brand' && q.isDefault);
      queryStringValues.push(`${brandParameters.key}=${brands.join(',')}`);
    }

    if (keyword) {
      let keywordParameters = queryStringParameters.find(
        (q) => q.type === 'keyword',
      );
      queryStringValues.push(`${keywordParameters.key}=${keyword}`);
    }

    if (sort && sort != 'order') {
      let keywordParameters = queryStringParameters.find(
        (q) => q.type === 'sort',
      );
      queryStringValues.push(`${keywordParameters.key}=${sort.value || sort}`);
    }

    const metaData = this.getMetaData(taxons, brands);

    //TM-1873 i=n removed.
    // if (!metaData.content) {
    //   let keywordParameters = queryStringParameters.find(
    //     (q) => q.type === "index"
    //   );
    //   queryStringValues.push(`${keywordParameters.key}=n`);
    // }

    return {
      queryString: queryStringValues.join('&'),
      slug: metaData.slug,
      metaData: metaData,
    };
  }

  getQueryStringFromSlug(slug) {
    const { metadata } = this.props;
    var data = metadata.find((m) => m.slug === slug);
    if (data) {
      return this.getQueryStringAndSlugFromSearchValues(
        [...data.categories],
        [data.brand],
        null,
      );
    }
    return {};
  }

  componentDidMount() {
    var searchValues = this.getSearchValuesFromQueryString();

    this.props.getProductsById(
      searchValues.taxons.join(','),
      searchValues.brands.join(','),
      searchValues.keyword,
    );

    const { success } = this.props.getByName(this.props.match.params.code, 1);
    if (!success) this.props.changePage({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // const querystringValues = queryString.parse(this.props.location.search);
    // const indexParameter = queryStringParameters.find(
    //   (x) => x.type === "index"
    // );
    // if (!querystringValues[indexParameter.key]) {
    //   querystringValues[indexParameter.key] = "n";
    //   const qs = queryString.stringify(querystringValues, { sort: false });

    //   this.props.history.push({
    //     search: qs,
    //   });
    // }

    setPrerenderTimeout();
  }

  applyFilter = (filter, isBrand) => {
    const id = parseInt(filter.target.value, 10);
    const searchValues = this.getSearchValuesFromQueryString();

    if (filter.target.checked) {
      if (isBrand) {
        searchValues.brands.push(Number(id));
      } else {
        searchValues.taxons.push(Number(id));
      }
    } else {
      if (isBrand) {
        searchValues.brands = searchValues.brands.filter(
          (q) => Number(q) !== Number(filter.target.value),
        );
      } else {
        searchValues.taxons = searchValues.taxons.filter(
          (q) => Number(q) !== Number(filter.target.value),
        );
      }
    }

    var generatedQueryString = this.getQueryStringAndSlugFromSearchValues(
      searchValues.taxons,
      searchValues.brands,
      searchValues.keyword,
      this.state.productOrder.value,
    );
    var pathName = generatedQueryString.slug.length
      ? '/urunler/' + generatedQueryString.slug
      : '/urunler';
    this.props.history.push({
      pathname: pathName,
      search: generatedQueryString.queryString,
    });
  };

  removeFilter = (filter) => {
    const searchValues = this.getSearchValuesFromQueryString();
    searchValues.brands = searchValues.brands.filter(
      (b) => Number(b) !== Number(filter.id),
    );
    searchValues.taxons = searchValues.taxons.filter((t) => t != filter.id);
    searchValues.keyword =
      filter.type === 'keyword' ? '' : searchValues.keyword;

    var generatedQueryString = this.getQueryStringAndSlugFromSearchValues(
      searchValues.taxons,
      searchValues.brands,
      searchValues.keyword,
      searchValues.sort,
    );
    var pathName = generatedQueryString.slug.length
      ? '/urunler/' + generatedQueryString.slug
      : '/urunler';
    this.props.history.push({
      pathname: pathName,
      search: generatedQueryString.queryString,
    });
  };

  clearFilters = () => {
    this.props.history.push({
      pathname: '/urunler',
      search: '',
    });
  };

  orderProducts = (order) => {
    this.setState({ productOrder: order });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const filters = [];

    let getSearchValuesFromQueryString = () => {
      const querystringValues = queryString.parse(nextProps.location.search);
      let taxons = [];
      let brands = [];
      let keyword = '';
      for (let i = 0; i < queryStringParameters.length; i++) {
        let parameter = queryStringParameters[i];
        if (querystringValues[parameter.key]) {
          if (parameter.type === 'taxon') {
            taxons = [
              ...taxons,
              ...querystringValues[parameter.key].split(','),
            ];
          } else if (parameter.type === 'brand') {
            brands = [
              ...brands,
              ...querystringValues[parameter.key].split(','),
            ];
          } else if (parameter.type === 'keyword') {
            keyword = querystringValues[parameter.key];
          }
        }
      }

      return {
        taxons: Array.from(new Set(taxons)),
        brands: Array.from(new Set(brands)),
        keyword,
      };
    };

    const searchValues = getSearchValuesFromQueryString();

    if (
      searchValues.taxons.length ||
      searchValues.brands.length ||
      searchValues.keyword
    ) {
      const filterT = searchValues.taxons;
      const filterB = searchValues.brands;

      if (searchValues.keyword) {
        filters.push({
          id: searchValues.keyword,
          apply: true,
          name: decodeURIComponent(searchValues.keyword),
          type: 'keyword',
        });
      }

      const filterArray = filterT.concat(filterB);

      if (filterArray.length) {
        nextProps.taxonomies.forEach((taxonomy) => {
          taxonomy.taxons.forEach((taxon) => {
            filterArray.forEach((filter) => {
              if (taxon.id == filter) {
                filters.push({
                  id: taxon.id,
                  apply: true,
                  name: taxon.name,
                  type: 'taxon',
                });
              }
            });
          });
        });
        nextProps.brands.forEach((brand) => {
          filterArray.forEach((filter) => {
            if (brand.id == filter) {
              filters.push({
                id: brand.id,
                apply: true,
                name: brand.name,
                type: 'brand',
              });
            }
          });
        });
      }
    }

    return {
      ...prevState,
      products: nextProps.products,
      filters,
      keyword: searchValues.keyword,
      brand: searchValues.brands,
      taxon: searchValues.taxons,
    };
  }

  isFavorite = (brand) => {
    const { favorites } = this.props;
    const brands = favorites && favorites.brands ? favorites.brands : [];
    return brands.some((p) => p.id === brand.id);
  };

  handleChangeMode = async (mode) => {
    const { match, page } = this.props;
    switch (mode) {
      case 'live':
        const brand = this.getBrand();
        const save = page.id
          ? () => this.props.savePage(page.id, page)
          : () =>
              this.props.createPage({
                name: brand.name,
                content: page.content || { items: [] },
                brandId: brand.id,
                embedded: 1,
                published: 1,
              });
        const { success } = await save();
        if (success) {
          toast.success('Kayıt edildi.');
          this.setState({ modified: false }, () =>
            this.props.getByName(match.params.code, 0),
          );
        }
        break;
      case 'cancel':
        this.setState({ mode: 'live' }, () =>
          this.props.getByName(match.params.code, 1),
        );
        break;
      case 'draft':
        this.setState({ mode }, () =>
          this.props.getByName(match.params.code, 0),
        );
        break;
      case 'publish':
        const result = await this.props.publishPage(page.id);
        if (result.success) {
          toast.success('Son yapılan değişikler yayına alındı.');
          this.setState({ mode }, () =>
            this.props.getByName(match.params.code, 1),
          );
        }
        break;
      case 'sort':
        this.setState({ showSortProducts: true });
        break;
      default:
        this.setState({ mode });
        break;
    }
  };

  handleItemClick = (command, item, index) => {
    const { page } = this.props;
    const collection = page.content ? page.content.items || [] : [];
    switch (command.name) {
      case 'add':
        item.id = collection.length + 1;
        collection.splice(index + 1, 0, item);
        this.setState({
          show: true,
          formData: item,
          index,
        });
        break;
      case 'edit':
        this.setState({
          show: true,
          formData: item,
          index,
        });
        return;
      case 'remove':
        const confirmed = window.confirm(
          'Görsel bileşen silinecektir, emin misiniz?',
        );
        if (confirmed) {
          _remove(collection, (p) => p === item);
        }
        break;
      default:
        break;
    }

    if (page.content) page.content.items = collection.concat();
    else page.content = { items: collection };
    this.change(page);
  };

  changeSorting = (sortOrder) => {
    const { page } = this.props;
    page.content['sort'] = sortOrder;
    this.change(page);
  };

  getBrand = () => {
    const { brands, location } = this.props;
    const query = this.getQueryString(location.search);
    return brands.find((p) => p.id === parseInt(query.b));
  };

  handleSubmit = (formData) => {
    const { page } = this.props;
    const index = page.content.items.findIndex((p) => p.id === formData.id);
    if (index === -1) page.content.items.push(formData);
    else page.content.items[index] = formData;
    page.content.items = page.content.items.concat();
    this.change(page);
    this.setState({ show: false, formData: {}, index: -1 });
  };

  addToFavorites = async (brand) => {
    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }

    const { success, error } = await this.props.addToFavorites(
      brand,
      favoriteTypes.brand,
    );

    if (success) {
      toast(this.customToastAddFavourite({ success, error }), {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });
    } else {
      toast.error(error.message);
    }
  };
  customToastAddFavourite = () => (
    <div className="custom-toast">
      <Icon icon="iSuccess" className="mr-3" />
      <div className="d-flex flex-column">
        <div>Ürün listene eklendi. </div>
        <a href="/hesabim/favorilerim">Listelerime Git</a>
      </div>
    </div>
  );
  removeFromFavorites = async (brand) => {
    const { success, error } = await this.props.removeFromFavorites(
      brand,
      favoriteTypes.brand,
    );

    if (success) {
      toast.success(`${brand.name} favorilerinizden çıkarıldı`);
    } else {
      toast.error(error.message);
    }
  };

  render() {
    const { location, user, products, page } = this.props;
    const query = this.getQueryString(location.search);

    const currentBrand = this.getBrand();
    let brandProducts = [];
    switch (this.state.productOrder.value) {
      case orderList[0].value:
        if (
          page &&
          page.content &&
          page.content.sort &&
          page.content.sort.length > 0
        ) {
          const sorted = page.content.sort;
          const length = products.length;
          brandProducts = products
            .map((product) => {
              const index = sorted.indexOf(product.id);
              return { ...product, order: index > -1 ? index : length };
            })
            .sort((a, b) => a.order - b.order);
        } else brandProducts = products;

        break;
      case orderList[2].value:
        brandProducts = products.sort((a, b) => b.isNew - a.isNew);
        break;
      // case orderList[5].value:
      //   brandProducts = products
      //     .concat()
      //     .sort((a, b) => Number(a.class) - Number(b.class));
      //   break;
      // case orderList[6].value:
      //   brandProducts = products
      //     .concat()
      //     .sort((a, b) => Number(b.class) - Number(a.class));
      //   break;
      case orderList[5].value:
        brandProducts = products
          .concat()
          .sort(
            (a, b) =>
              ((a.total * (100 + a.tax)) / 100 || 0).toFixed(2) -
              ((b.total * (100 + b.tax)) / 100 || 0).toFixed(2),
          );
        break;
      case orderList[6].value:
        brandProducts = products
          .concat()
          .sort(
            (a, b) =>
              ((b.total * (100 + b.tax)) / 100 || 0).toFixed(2) -
              ((a.total * (100 + a.tax)) / 100 || 0).toFixed(2),
          );
        break;
      default:
        brandProducts = products;
        break;
    }

    const favorite = this.isFavorite(currentBrand || {});
    const editable =
      user.editor === true &&
      (!user.brandId || parseInt(query.b) === user.brandId);
    breadcrumbs[2].title = currentBrand ? currentBrand.name : '-';
    const items = page.content ? page.content.items || [] : [];
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-12 p-0-mobile">
              <Breadcrumb items={breadcrumbs} />
            </div>
            {editable && (
              <div className="col-12">
                <EditableToolbar
                  mode={this.state.mode}
                  modified={this.state.modified}
                  onChange={this.handleChangeMode}
                  published={page.published}
                  publishVisible={true}
                  saveVisible={true}
                >
                  {toolbarItems.map((item, i) => (
                    <IconButton
                      className="btn-primary mx-2 text-capitalize"
                      icon={item.icon}
                      onClick={() => this.handleChangeMode(item.name)}
                      key={i}
                    >
                      {item.title}
                    </IconButton>
                  ))}
                </EditableToolbar>
              </div>
            )}
            {currentBrand && (
              <div className="col-md-12 brandShow p-0-mobile">
                <div className="row border overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative radius-20">
                  <figure className="w-100" style={{ position: 'relative' }}>
                    <img
                      src={currentBrand.banner}
                      alt="..."
                      className="brand-show-layer-3"
                    />
                  </figure>
                  <div className="col-auto d-lg-block ml-2 p-0-mobile">
                    <figure>
                      <img
                        className="brand-show-mark-logo"
                        src={currentBrand.logo}
                        alt={currentBrand.name}
                      />
                    </figure>
                  </div>
                  <div className="col d-flex flex-column brand-show-top-box">
                    <h3>{currentBrand.name}</h3>
                    <p
                      className="fs-14"
                      style={{
                        width: '80%',
                      }}
                    >
                      {currentBrand.description}
                    </p>
                    <button
                      className="btn btn-link position-absolute"
                      style={{ right: '10px', top: '-10px' }}
                      onClick={() =>
                        favorite
                          ? this.removeFromFavorites(
                              currentBrand,
                              favoriteTypes.brand,
                            )
                          : this.addToFavorites(
                              currentBrand,
                              favoriteTypes.brand,
                            )
                      }
                      title={
                        favorite ? 'Favorilerden çıkart' : 'Favorilere ekle'
                      }
                    >
                      <img
                        src={favorite ? iconHearth : iconHearthOuter}
                        alt={currentBrand.name}
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="col-sm-3 brand-show-filter">
              <SideBar
                filters={this.state.filters}
                taxonomies={this.props.taxonomies}
                brands={this.props.brands}
                applyFilter={this.applyFilter}
                selectedBrandId={parseInt(query.b)}
              />
            </div>
            <div className="col-sm-9 brand-show-list xs-mt-30 p-0">
              <div className="row by-dedede py-2 mb-3 brand-show-mobile-filter">
                <div className="col-6">
                  <button
                    onClick={() => this.setState({ showFilterModal: true })}
                    className="btn btn-link text-decoration-none w-100 text-dark text-capitalize font-weight-500 br-dedede"
                  >
                    Filtrele
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={() => this.setState({ showSortModal: true })}
                    className="btn btn-link text-decoration-none w-100 text-dark text-capitalize font-weight-500"
                  >
                    Sırala
                  </button>
                </div>
              </div>
              <Filters
                items={this.state.filters}
                onRemove={this.removeFilter}
                onClearAll={this.clearFilters}
              />
              <div className="d-flex flex-row justify-content-between w-100">
                <span className="mt-2 fs-12" style={{ fontWeight: '500' }}>
                  {brandProducts.length} ürün var
                </span>
                <Select
                  isSearchable={false}
                  onChange={this.orderProducts}
                  options={orderList}
                  defaultValue={this.state.productOrder}
                  value={this.state.productOrder}
                  className="brand-show-filter-select"
                  styles={customStyles}
                />
              </div>

              <div className="row">
                <div className="col-12 mt-4">
                  <Showcase
                    items={brandProducts}
                    advertorials={items}
                    component={AdvertorialView}
                    menuItems={menuItems}
                    mode={this.state.mode}
                    onItemClick={this.handleItemClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FilterModal
          show={this.state.showFilterModal}
          filters={this.state.filters}
          taxonomies={this.props.taxonomies}
          brands={this.props.brands}
          applyFilter={this.applyFilter}
          clearFilters={this.clearFilters}
          removeFilter={this.removeFilter}
          onCloseClick={() => this.setState({ showFilterModal: false })}
        />
        <SortModal
          show={this.state.showSortModal}
          order={this.orderProducts}
          onCloseClick={() => this.setState({ showSortModal: false })}
        />
        {this.state.showSortProducts && (
          <SortProductsModal
            show={true}
            onClose={() => this.setState({ showSortProducts: false })}
            items={brandProducts}
            page={page}
            onChange={this.changeSorting}
          />
        )}
        <FormModal
          show={this.state.show}
          onClose={() => this.setState({ show: false })}
          onSubmit={this.handleSubmit}
          schema={FormSchemas.brandLinkItem.schema}
          uiSchema={FormSchemas.brandLinkItem.uiSchema}
          formData={this.state.formData}
        />
      </>
    );
  }
}

Products.propTypes = {};

const mapStateToProps = ({
  AccountReducer,
  ContentReducer,
  ProductReducer,
}) => ({
  user: AccountReducer.user,
  products: ProductReducer.products,
  brands: ProductReducer.brands,
  taxonomies: ProductReducer.taxonomies,
  favorites: AccountReducer.favorites,
  page: ContentReducer.page,
  metadata: ProductReducer.metadata,
});

const mapDispatchToProps = {
  getProductsById: actions.getProductsById,
  getByName: getByName,
  savePage: savePage,
  changePage: changePage,
  createPage: createPage,
  publishPage: publishPage,
  search: actions.search,
  loginRequired: accountActions.loginRequired,
  addToFavorites: accountActions.addToFavorites,
  removeFromFavorites: accountActions.removeFromFavorites,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
