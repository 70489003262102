import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from 'react-slick';
import zIndex from '@material-ui/core/styles/zIndex';

class Index extends Component {
  state = {
    currentImage: 0,
  };

  openLightbox = (index) => {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };
  gotoPrevious = () => {
    const { length } = this.props.images;
    this.setState({
      currentImage: (this.state.currentImage + length - 1) % length,
    });
  };
  gotoNext = () => {
    this.setState({
      currentImage: (this.state.currentImage + 1) % this.props.images.length,
    });
  };

  render() {
    const { images } = this.props;
    const { currentImage, lightboxIsOpen } = this.state;

    const settings = {
      dots: false,
      slidesToShow: images.length > 1 ? 3 : 1, // Eğer tek resim varsa 1 göster
      slidesToScroll: 1,
      initialSlide: 0,
      swipeToSlide: true,
      infinite: images.length > 3,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: images.length > 1 ? 2 : 1, // Tek resimse 1 göster
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: images.length > 1 ? 3 : 1,
            slidesToScroll: 2,
            infinite: images.length > 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: images.length > 1 ? 2 : 1,
            slidesToScroll: 2,
            infinite: images.length > 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      customPaging: function (i) {
        return <div className="slide-paging" />;
      },
    };

    return (
      <div className="row m-0 w-100">
        <Slider {...settings} className="product-detail-slide w-100">
          {images.map((image, index) => (
            <div className="gallery-item">
              <div className="card mb-4">
                <button
                  type="button"
                  className="btn btn-link mb-3"
                  onClick={() => this.openLightbox(index)}
                >
                  <img
                    src={image.src}
                    alt=""
                    id='custom-image-gallery-item'
                  />
                </button>
                {/* <span className="product-show-caption">Tam Sayılarda Çarpma</span> */}
              </div>
            </div>
          ))}
        </Slider>
        <div className="product-detail-slide-mobile w-100">
          {images.map((image, index) => (
            <div className="gallery-item">
              <div className="card mb-4">
                <button
                  type="button"
                  className="btn btn-link mb-3"
                  onClick={() => this.openLightbox(index)}
                >
                  <img
                    className="img-fluid"
                    src={image.src}
                    alt=""
                    // style={{ height: 293, width: 207 }}
                  />
                </button>
                {/* <span className="product-show-caption">Tam Sayılarda Çarpma</span> */}
              </div>
            </div>
          ))}
        </div>
        {lightboxIsOpen && (
          <Lightbox
            mainSrc={images[currentImage].src}
            nextSrc={images[(currentImage + 1) % images.length].src}
            prevSrc={
              images[(currentImage + images.length - 1) % images.length].src
            }
            imageTitle={`${currentImage + 1}/${images.length}`}
            onCloseRequest={this.closeLightbox}
            onMovePrevRequest={this.gotoPrevious}
            onMoveNextRequest={this.gotoNext}
            reactModalStyle={{ overlay: { zIndex: 999999 } }} // Z-index burada güncellenir
          />
        )}
      </div>
    );
  }
}

Index.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Index;
