import React, { useEffect, useState } from 'react';
import * as actions from '../../routes/actions/Product';
import * as recommendationProductActions from '../../routes/actions/RecommendationProducts';
import * as productActions from '../../routes/actions/Product';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Icon from '../../components/Icon/Index';
import { replaceDotWithComma } from '../../helpers';

const ProductBookRecommendation = ({
  title,
  addProductToBasket,
  basketKey,
  basket,
  eventType,
  productId,
  product,
  user,
  ...props
}) => {
  const [bookList, setBookList] = useState([]);
  const [selectedBookList, setSelectedBookList] = useState([]);

  useEffect(() => {
    let output = [product];
    if (props.recommendationProducts?.length) {
      for (let i = 0; i < 2; i++) {
        if (
          props.recommendationProducts[i] &&
          output.filter((x) => x.id == props.recommendationProducts[i].id)
            .length == 0
        ) {
          output.push(props.recommendationProducts[i]);
        }
      }
    }

    for (let i = 0; i < output.length; i++) {
      output[i].select = true;
    }

    for (let i = 0; i < bookList.length; i++) {
      let itemIndex = output.findIndex((m) => m.id == bookList[i].id);
      if (itemIndex > -1) {
        output[itemIndex].select = bookList[i].select;
      }
    }
    setBookList(output);
    setSelectedBookList(output);
  }, [product, props.recommendationProducts]);

  useEffect(() => {
    getDatas();
  }, [basket]);

  function getDatas() {
    const clientId = user ? user.id : '';
    const basket_ = basket.map((item) => ({
      uid: item.product.id,
      sepetfiyati: item.total,
      adet: item.quantity,
    }));
    let model = {
      eventtipi: eventType,
      satiskanali: 1,
      urun: {
        uid: productId,
      },
      sepeturunleri: basket_,
      musteri: {
        mid: clientId,
      },
    };
  }

  const basketSubmit = async (selectedBookList) => {
    for (let i = 0; i < selectedBookList.length; i++) {
      const item = {
        key: basketKey,
        productId: bookList[i].id,
        quantity: 1,
        update: false,
        ...product,
        isDigital: bookList[i].isDigital,
      };

      const { success, error } = await addProductToBasket(
        item,
        props.allTaxonomies,
      );

      if (success) {
        toast.success('Ürün sepetinize eklendi.');
      } else {
        toast.error(error.message);
      }
    }
  };

  function toggleBookSelect(newValue, bookIndex) {
    let newBookList = [...bookList];
    newBookList[bookIndex].select = newValue;
    const selectedBooks = bookList.filter((b) => b.select);
    setSelectedBookList(selectedBooks);
  }

  function totalPrice() {
    let output = 0;
    for (let book of selectedBookList) {
      output += (book.price * (100 + book.tax)) / 100 || 0;
    }
    return replaceDotWithComma(output.toFixed(2));
  }

  function totalPriceWithDiscount() {
    let output = 0;
    for (let book of selectedBookList) {
      output += book.total || 0;
    }
    return replaceDotWithComma(output.toFixed(2));
  }

  const total = totalPrice();
  const discountedTotal = totalPriceWithDiscount();

  return (
    <div
      className="product-book-recommendation-container"
      style={{
        display: props.recommendationProducts.length ? 'block' : 'none',
      }}
    >
      <h2>{title}</h2>
      <div className="d-flex flex-column flex-lg-row">
        <div className="recommendation-box">
          {bookList.map((book, i) => (
            <a
              className="recommendation-item"
              href={`/urunler/detay/${book.id}`}
              key={i}
            >
              <div className="img-box">
                <img
                  className="img-fluid"
                  src={book.imageUrl}
                  alt={book.name}
                />
              </div>
              <Icon icon="iPlusBlack" />
            </a>
          ))}
        </div>
        <div className="price-container bundle-product-container">
          <div className="price-box justify-content-between">
            <div className="total-price ">
              <span className="total-price-text">Toplam Fiyat</span>
              <div>
                {total !== discountedTotal && (
                  <span className="calculate-old-price">{total} ₺</span>
                )}
                <span className="calculate-real-price">
                  {totalPriceWithDiscount()} ₺
                </span>
              </div>
            </div>
            <button
              className="btn btn-book-recommendation"
              onClick={() => basketSubmit(selectedBookList)}
            >
              Sepete Ekle
            </button>
          </div>
          <div className="recommendation-box-details">
            {bookList.map((book, i) => (
              <div
                className="recommendation-item justify-content-between"
                key={i}
              >
                <div className="d-flex">
                  <input
                    type="checkbox"
                    checked={book.select}
                    onClick={() => toggleBookSelect(!book.select, i)}
                  />
                  <div>{book.name}</div>
                </div>

                <div className="speacial-bundle d-flex ">
                  <span>
                    {book.discount !== 0 && (
                      <div className="slide-price-old">
                        {replaceDotWithComma(
                          (book.priceWithTax || 0).toFixed(2),
                        )}{' '}
                        ₺
                      </div>
                    )}
                  </span>
                  <span>
                    &nbsp;{replaceDotWithComma((book.total || 0).toFixed(2))}
                    &nbsp;₺
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  ProductReducer,
  RecommendationProductsReducer,
  AccountReducer,
}) => ({
  basketKey: ProductReducer.basketKey,
  basket: ProductReducer.basket,
  user: AccountReducer.user,
  recommendationProducts: RecommendationProductsReducer.products,
  allTaxonomies: ProductReducer.taxonomies,
});
const mapDispatchToProps = {
  addProductToBasket: actions.addProductToBasket,
  getBasket: productActions.getBasket,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductBookRecommendation);
