import React, { PureComponent } from 'react';
import { RateBoard, ScoreBadge, Gallery } from '../../components';
import { formatDate as dateFormatter } from '../../helpers';
import CommentModal from '../../components/Modal/CommentModal';
import Rating from 'react-rating';
import EmptyComment from './EmptyComment';
import { toast } from 'react-toastify';
import Icon from '../../components/Icon/Index';
import { calc } from '../../helpers/mathExtensions';

const Star = (props) => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FBBC06"
      fillRule="nonzero"
      d="M18.3 22.913c-.382 0-.76-.115-1.08-.34l-5.216-3.67-5.218 3.67a1.883 1.883 0 0 1-2.145.007c-.641-.441-.907-1.228-.656-1.944l2.05-5.86L.76 11.181c-.644-.44-.915-1.223-.671-1.94.243-.713.94-1.195 1.727-1.195h.013l6.487.05 1.957-5.89C10.51 1.49 11.212 1 12.002 1c.79 0 1.492.49 1.728 1.207l1.956 5.89 6.484-.05h.017c.783 0 1.485.482 1.723 1.195.246.718-.022 1.5-.667 1.94l-5.273 3.593 2.053 5.861c.248.714-.018 1.501-.654 1.944-.32.222-.696.333-1.07.333z"
    />
  </svg>
);

const StarO = (props) => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#DEDEDE"
      fillRule="nonzero"
      d="M18.3 22.913c-.382 0-.76-.115-1.08-.34l-5.216-3.67-5.218 3.67a1.883 1.883 0 0 1-2.145.007c-.641-.441-.907-1.228-.656-1.944l2.05-5.86L.76 11.181c-.644-.44-.915-1.223-.671-1.94.243-.713.94-1.195 1.727-1.195h.013l6.487.05 1.957-5.89C10.51 1.49 11.212 1 12.002 1c.79 0 1.492.49 1.728 1.207l1.956 5.89 6.484-.05h.017c.783 0 1.485.482 1.723 1.195.246.718-.022 1.5-.667 1.94l-5.273 3.593 2.053 5.861c.248.714-.018 1.501-.654 1.944-.32.222-.696.333-1.07.333z"
    />
  </svg>
);

const CommentGain = (activityType, price) => (
  <>
    <div className="d-flex flex-row justify-content-center mt-2">
      <ScoreBadge
        icon="point"
        title={`+${activityType.Score} Puan`}
        className="badge-score mr-2"
        value={activityType.Score}
        productDetailBadge
      />
      <ScoreBadge
        icon="coin"
        title={`+${(calc(price, '*', activityType.Coins) / 10).toFixed(
          0,
        )}  Jeton`}
        className="badge-coin"
        value={activityType.Coins.toFixed(0)}
        productDetailBadge
      />
    </div>
  </>
);

const Thumbnails = ({ previews }) => (
      <>
            <div className="d-flex flex-row thumbnails-container">
              <Gallery images={previews} />
            </div>
      </>
    );

class Comments extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { 
      rating: 0,
      errors: { comment: false, rating: false },
      files : this.props.files || []
   };
    this.comment = React.createRef();
  }

  componentDidMount() {}

  handleRating = (value) => {
    this.setState({ rating: value });
  };

  handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    this.setState({ files: selectedFiles });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.props.user.id) {
      this.props.loginRequired(true);
      return;
    }
    if (!this.props.product.isBought) {
      toast(this.customToastComment(), {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        closeButton: false,
      });
      this.props.onToggle();
      return;
    }
    const commentText = this.comment.current.value;
    const errors = { comment: false, rating: false };

    errors.comment = !commentText.length;
    errors.rating = !this.state.rating;

    this.setState({ errors }, () => {
      if (!errors.comment && !errors.rating) {
        const comment = {
          customerId: this.props.user.id,
          name: `${this.props.user.firstname} ${this.props.user.lastname}`,
          date: new Date(),
          email: this.props.user.email,
          star: this.state.rating * 10,
          text: commentText,
          productId: this.props.product.id,
          repliedTo: 0,
          images: this.state.files
        };

        this.props.onSubmit(comment);
      }
    });
  };

  customToastComment = () => (
    <div className="custom-toast-danger">
      <Icon icon="iInfo" className="mr-3" />
      <span>
        Değerlendirme yapabilmek için bu ürünü satın almış olmalısınız
      </span>
    </div>
  );
  formatDate(date) {
    return date ? dateFormatter.date(new Date(date)) : null;
  }

  render() {
    const comments = [];
    const replies = [];

    this.props.comments.forEach((comment) => {
      comment.repliedTo ? replies.push(comment) : comments.push(comment);
    });

    const userComments = [];

    this.props.comments.forEach((comment) => {
      !comment.repliedTo && userComments.push(comment);
    });
    return (
      <>
        {this.props.comments.filter((c) => c.tur === 0).length > 0 ? (
          <div>
            <div className="comments-rate-container">
              <div className="rate-box">
                <div className="d-flex rate-box-container">
                  <h2 className="comment-rate-score mr-1">
                    {this.props.totalRating.replace('.', ',')}
                  </h2>
                  <div className="d-flex flex-column">
                    <h2 className="rating-component text-center w-100 mb-2">
                      <Rating
                        emptySymbol={<StarO className="icon" />}
                        fullSymbol={<Star className="icon" />}
                        initialRating={this.props.totalRating}
                        readonly
                      />
                    </h2>
                    <div className="rate-total-comment">
                      <span className="mr-1">
                        {userComments.filter((u) => u.tur === 0).length}
                      </span>
                      yorum
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <button
                    className="btn comment-button"
                    onClick={this.props.onToggle}
                  >
                    Değerlendirme Yap
                  </button>
                  {CommentGain(this.props.activityType, this.props.price)}
                </div>
              </div>
              <div className="comment-detail-container">
                <RateBoard comments={this.props.comments} />
              </div>
            </div>
            <div className="media mt-4">
              <ul className="list-group list-group-flush full-w">
                {comments
                  .filter((c) => c.tur === 0)
                  .map((comment) => (
                    <>
                      <li
                        className="list-group-item comment-item main-comment"
                        key={comment.id}
                      >
                        <div className="d-flex">
                          <figure className="figure mr-2 float-left">
                            <div className="default-avatar">
                              {comment.avatar ? (
                                <img
                                  src={comment.avatar.path}
                                  width="28"
                                  height="28"
                                  alt=""
                                />
                              ) : comment.name ? (
                                comment.name.charAt(0).toUpperCase() +
                                comment.name
                                  .split(' ')
                                  .slice(-1)[0]
                                  .charAt(0)
                                  .toUpperCase()
                              ) : (
                                'DK'
                              )}
                            </div>
                          </figure>

                          <div className="comment-box">
                            <span className="comment-user-name mt-0">
                              {comment.name}
                            </span>
                            <br />
                            <h2 className="rating-component mb-1">
                              <Rating
                                emptySymbol={<StarO className="icon" />}
                                fullSymbol={<Star className="icon" />}
                                initialRating={comment.star / 10}
                                readonly
                              />
                            </h2>
                            <p className="comment">{comment.text}</p>
                            <span className="time">
                              {this.formatDate(comment.date)}
                            </span>
                            <Thumbnails previews={comment.resimUrls.map((item) => ({ src: item }))} />
                          </div>
                        </div>
                        {replies
                          .filter((reply) => reply.repliedTo === comment.id)
                          .map((reply) => (
                            <ul>
                              <li
                                className="list-group-item comment-item border-none p-0"
                                key={reply.id}
                              >
                                <div className="d-flex">
                                  <figure className="figure mr-2 float-left">
                                    <div className="default-avatar">
                                      {reply.avatar ? (
                                        <img
                                          src={reply.avatar.path}
                                          width="28"
                                          height="28"
                                          alt=""
                                        />
                                      ) : reply.name ? (
                                        reply.name.charAt(0).toUpperCase() +
                                        reply.name
                                          .split(' ')
                                          .slice(-1)[0]
                                          .charAt(0)
                                          .toUpperCase()
                                      ) : (
                                        'DK'
                                      )}
                                    </div>
                                  </figure>
                                  <div>
                                    <span className="comment-user-name mt-0">
                                      {reply.name}
                                    </span>
                                    <br />
                                    <h2 className="rating-component mb-1">
                                      <Rating
                                        emptySymbol={<StarO className="icon" />}
                                        fullSymbol={<Star className="icon" />}
                                        initialRating={reply.star / 10}
                                        readonly
                                      />
                                    </h2>
                                    <p className="comment">{reply.text}</p>
                                    <span className="time">
                                      {this.formatDate(reply.date)}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          ))}
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </div>
        ) : (
          <EmptyComment
            productPrice={this.props.price}
            activityType={this.props.activityType}
            onToggle={this.props.onToggle}
            show={this.props.show}
            handleSubmit={this.handleSubmit}
            initialRating={this.state.rating}
            handleRating={this.handleRating}
            product={this.props.product}
            errors={this.state.errors}
            handleFileChange={this.handleFileChange}
          />
        )}
        <CommentModal
          className={this.props.show ? 'd-block' : 'd-none'}
          show={this.props.show}
          handleSubmit={this.handleSubmit}
          initialRating={this.state.rating}
          handleRating={this.handleRating}
          errors={this.state.errors}
          comment={this.comment}
          onToggle={this.props.onToggle}
          product={this.props.product}
          handleFileChange={this.handleFileChange}
        />
      </>
    );
  }
}

export default Comments;
