import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import * as actions from '../../routes/actions/RecommendationProducts';
import * as productActions from '../../routes/actions/Product';
import { Link } from 'react-router-dom';

const ProductDiscountSlider = ({ ...props }) => {
  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    const clientId = props.user ? props.user.id : '';
    const basket_ = props.basket.map((item) => ({
      uid: item.product.id,
      sepetfiyati: item.total,
      adet: item.quantity,
    }));
    let model = {
      eventtipi: 4,
      satiskanali: 1,
      urun: {
        uid: 0,
      },
      sepeturunleri: basket_,
      musteri: {
        mid: clientId,
      },
    };
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow:
      props.recommendationCategories.length < 3
        ? props.recommendationCategories.length
        : 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            props.recommendationCategories.length < 2
              ? props.recommendationCategories.length
              : 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="product-discount-container">
      <div className="d-flex">
        <h2>Bu Kategoriler İlgini Çekebilir</h2>
        <div className="more-arrow" />
      </div>
      <Slider {...settings}>
        {props.recommendationCategories.map((category) => (
          <div key={category.id} className="product-discount-item">
            <img src={category.image} alt={category.name} />
            <div className="detail-box">
              <div className="detail-textarea">
                <h5>{category.name}</h5>
                <span>{category.description}</span>
              </div>
              <Link className="btn" to={`/urunler?t=${category.id}`}>
                Ürünleri Gör
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const mapStateToProps = ({
  AccountReducer,
  RecommendationProductsReducer,
  ProductReducer,
}) => ({
  user: AccountReducer.user,
  basket: ProductReducer.basket,
  basketKey: ProductReducer.basketKey,
  recommendationCategories: RecommendationProductsReducer.categories,
});

const mapDispatchToProps = {
  addProductToBasket: productActions.addProductToBasket,
  getBasket: productActions.getBasket,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductDiscountSlider);
