import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { replaceDotWithComma } from '../../helpers';
import { Link } from 'react-router-dom';

import * as actions from '../../routes/actions/RecommendationProducts';
import * as productActions from '../../routes/actions/Product';

class ProductRecommendationProduct extends Component {
  constructor(props) {
    super(props);
  }
  async componentDidMount() {
    if (!this.props.basket || !this.props.basket.length) {
      await this.props.getBasket();
    }

    const clientId = this.props.user ? this.props.user.id : '';
    const basket = this.props.basket.map((item) => ({
      uid: item.product.id,
      sepetfiyati: item.total,
      adet: item.quantity,
    }));
    let model = {
      eventtipi: this.props.eventType,
      satiskanali: 1,
      urun: {
        uid: this.props.productId,
      },
      sepeturunleri: basket,
      musteri: {
        mid: clientId,
      },
    };
  }

  render(props) {
    var products = this.props.recommendationProducts;
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: products.length < 4 ? products.length : 4,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: products.length < 3 ? products.length : 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: products.length < 2 ? products.length : 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: products.length < 2 ? products.length : 2,
          },
        },
      ],
    };

    return (
      products && (
        <div
          className={'main-book-slider product-detail-slider'}
          key={products.length}
          style={{
            display: products.length ? 'block' : 'none',
          }}
        >
          <div className="d-flex align-items-center">
            <h2> {this.props.title}</h2>
          </div>

          {products.length > 0 && (
            <Slider {...settings}>
              {products.map((product, i) => (
                <div
                  className="book-detail-container"
                  key={`${i}-${product.id}`}
                  title={product.name}
                >
                  <div className="book-detail-box">
                    <a
                      className="img-box"
                      href={`/urunler/detay/${product.id}`}
                    >
                      <img
                        className="img-fluid"
                        src={product.imageUrl}
                        alt=""
                      />
                      <div className="book-detail-body text-left">
                        <div className="h-auto">{product.name}</div>
                        {product.discount !== 0 && (
                          <div className="slide-price-old h-auto">
                            {replaceDotWithComma(
                              (
                                product.total / (1 - product.discount / 100) ||
                                0
                              ).toFixed(2),
                            )}{' '}
                            ₺
                          </div>
                        )}
                        <div className="h-auto">
                          <span>
                            {replaceDotWithComma(product.total.toFixed(2))} ₺
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = ({
  AccountReducer,
  RecommendationProductsReducer,
  ProductReducer,
}) => ({
  user: AccountReducer.user,
  basket: ProductReducer.basket,
  basketKey: ProductReducer.basketKey,
  recommendationProducts: RecommendationProductsReducer.products,
});

const mapDispatchToProps = {
  addProductToBasket: productActions.addProductToBasket,
  getBasket: productActions.getBasket,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductRecommendationProduct);
