import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import SideBar from './SideBar';
import { connect } from 'react-redux';
import * as actions from '../../routes/actions/Product';
import {
  Breadcrumb,
  ClassList,
  OrderList,
  ProductList,
  ViewList,
} from '../../components';
// import Select from "react-select";
import { customStyles, setPrerenderTimeout, arrayEquals } from '../../helpers';
import { orderList } from '../../services/constants';
import FilterModal from './FilterModal';
import SortModal from './SortModal';
import Filters from '../../components/Card/Filters';
import { Helmet } from 'react-helmet';
import { queryStringParameters } from '../../services/constants';
import Icon from '../../components/Icon/Index';
import StickyBox from 'react-sticky-box';
import Skeleton from '@material-ui/lab/Skeleton';

class Products extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showFilterModal: false,
      showSortModal: false,
      filters: [],
      products: [],
      productOrder: orderList[0],
      keyword: '',
      h1SeoTag: '',
      brand: [],
      taxon: [],
      isShowMarkalar: false,
      firstFilteredTaxonomyId: 0,
      showMoreList: true,
      stickySideBar: true,
      oldScrollY: 0,
      stickyScrollTop: 0,
      breadcrumbs: [
        {
          title: 'Anasayfa',
          url: '/',
        },
        {
          title: 'Tüm Ürünler',
          active: true,
        },
      ],
    };
  }

  selectSort = (item) => {
    if (this.state.productOrder.label === item.label) {
      this.setState({ productOrder: item.label });
    }
    this.state.productOrder.label === item.label
      ? this.setState({ productOrder: item.label })
      : this.setState({ productOrder: item.label });
    this.orderProducts(item);
  };

  list3 = () => {
    this.setState({
      showMoreList: false,
    });
  };

  list4 = () => {
    this.setState({
      showMoreList: true,
    });
  };

  getQueryStringFromSlug(slug) {
    const { metadata } = this.props;
    var data = metadata.find((m) => m.slug === slug);
    if (data) {
      return this.getQueryStringAndSlugFromSearchValues(
        [...data.categories],
        [data.brand],
        null,
      );
    }
    return {};
  }

  getMetaData(taxons, brands) {
    const currentMetadata = {
      title: "Tonguç'la çalış, Başarıya alış!",
      description: null,
      heading: null,
      content: null,
      slug: '',
    };

    if (taxons.length || brands.length) {
      let selectedBrand =
        brands.length === 1
          ? Number(brands[0])
          : brands.length === 2 &&
              brands.some((b) => b === '15302') &&
              brands.some((b) => b === '15308')
            ? 15302
            : null;
      const { metadata } = this.props;

      const data = metadata.find(
        (m) =>
          ((selectedBrand === null && !m.brand) || m.brand === selectedBrand) &&
          m.categories.length === taxons.length &&
          m.categories.every((mc) => taxons.some((t) => Number(t) === mc)),
      );

      if (data) {
        currentMetadata.title = data.title;
        currentMetadata.heading = data.heading;
        currentMetadata.description = data.metaDescription;
        currentMetadata.content = data.content;
        currentMetadata.slug = data.slug;
      }
    }

    return currentMetadata;
  }

  getSearchValuesFromQueryString() {
    const querystringValues = queryString.parse(this.props.location.search);
    let taxons = [];
    let brands = [];
    let keyword = '';
    let sort = orderList[0];
    for (let i = 0; i < queryStringParameters.length; i++) {
      let parameter = queryStringParameters[i];
      if (querystringValues[parameter.key]) {
        if (parameter.type === 'taxon') {
          taxons = [...taxons, ...querystringValues[parameter.key].split(',')];
        } else if (parameter.type === 'brand') {
          brands = [...brands, ...querystringValues[parameter.key].split(',')];
        } else if (parameter.type === 'keyword') {
          keyword = querystringValues[parameter.key];
        } else if (parameter.type === 'sort') {
          var sortOption = orderList.find(
            (o) => o.value === querystringValues[parameter.key],
          );
          if (sortOption) {
            sort = sortOption;
          }
        }
      }
    }

    return {
      taxons: Array.from(new Set(taxons)),
      brands: Array.from(new Set(brands)),
      keyword,
      sort,
    };
  }

  getQueryStringAndSlugFromSearchValues(taxons, brands, keyword, sort) {
    let queryStringValues = [];
    let slugParts = []; // Slug parçalarını burada toplayacağız
    let breadcrumbs = [
      {
        title: 'Anasayfa',
        url: '/',
      },
      {
        title: 'Tüm Ürünler',
        active: true,
      },
    ];
    if (taxons && taxons.some((x) => !isNaN(x))) {
      let taxonParameters = queryStringParameters.filter(
        (q) => q.type === 'taxon',
      );
      const { taxonomies } = this.props;
      let selectedTaxonomies = taxonomies
        .filter((t) =>
          t.taxons.some(
            (tx) => taxons.includes(tx.id.toString()) || taxons.includes(tx.id),
          ),
        ) // seçili gelen taxonların grubunun seçilmesi
        .map((t) => {
          return {
            id: t.id,
            code: t.code,
            name: t.name,
            taxons: t.taxons
              .filter(
                (tx) =>
                  taxons.includes(tx.id.toString()) || taxons.includes(tx.id),
              )
              .map((x) => ({ id: x.id, name: x.name, code: x.code })), // Taxon kodunu ekledik
          };
        }); // seçili gelen taxonların seçilmesi
      let defaultTaxons = [];
      for (let i = 0; i < selectedTaxonomies.length; i++) {
        const taxonomy = selectedTaxonomies[i];
        var taxonParameter = taxonParameters.find((tp) =>
          taxonomy.code.includes(tp.parentCode),
        );
        if (taxonParameter) {
          queryStringValues.push(
            `${taxonParameter.key}=${taxonomy.taxons.map((tx) => tx.id).join(',')}`,
          );
          slugParts.push(
            taxonomy.code.toLowerCase().replace(/\s+/g, '-'), // Taxonomy kodunu ekliyoruz
            ...taxonomy.taxons.map((tx) =>
              tx.code.toLowerCase().replace(/\s+/g, '-'),
            ), // Taxon kodlarını ekliyoruz
          );
          // Breadcrumbs güncelleme
          breadcrumbs.push(
            ...taxonomy.taxons.map((tx) => ({
              title: tx.name,
              active: true,
            })),
          );
        } else {
          defaultTaxons = [...defaultTaxons, ...taxonomy.taxons];
        }
      }
      if (defaultTaxons.length) {
        let taxonParameter = taxonParameters.find((tp) => tp.isDefault);
        queryStringValues.push(
          `${taxonParameter.key}=${defaultTaxons.map((tx) => tx.id).join(',')}`,
        );
        slugParts.push(
          ...defaultTaxons.map((tx) =>
            tx.code.toLowerCase().replace(/\s+/g, '-'),
          ), // Default taxon kodlarını ekliyoruz
        );
        breadcrumbs.push(
          ...defaultTaxons.map((tx) => ({
            title: tx.name,
            active: true,
          })),
        );
      }
    }

    if (brands && brands.some((x) => !isNaN(x))) {
      let brandParameters =
        queryStringParameters.find((q) => q.type === 'brand' && !q.isDefault) ||
        queryStringParameters.find((q) => q.type === 'brand' && q.isDefault);
      queryStringValues.push(`${brandParameters.key}=${brands.join(',')}`);
      const selectedBrands = this.props.brands.filter(
        (b) => brands.includes(b.id.toString()) || brands.includes(b.id),
      );
      breadcrumbs.push(
        ...selectedBrands.map((brand) => ({
          title: brand.name,
          active: true,
        })),
      );
    }

    if (keyword) {
      let keywordParameters = queryStringParameters.find(
        (q) => q.type === 'keyword',
      );
      queryStringValues.push(`${keywordParameters.key}=${keyword}`);
    }

    if (sort && sort !== 'order') {
      let sortParameters = queryStringParameters.find((q) => q.type === 'sort');
      queryStringValues.push(`${sortParameters.key}=${sort.value || sort}`);
    }

    const metaData = this.getMetaData(taxons, brands);
    if (
      !metaData.content &&
      metaData.content !== '' &&
      metaData.content !== null
    ) {
      let indexParameters = queryStringParameters.find(
        (q) => q.type === 'index',
      );
      queryStringValues.push(`${indexParameters.key}=n`);
    }

    // Slug'ı oluştururken slug parçalarını birleştiriyoruz
    let slug = slugParts.join('-');
    this.setState({ breadcrumbs });
    return {
      queryString: queryStringValues.join('&'),
      slug: slug || metaData.slug, // metaData.slug hala fallback olarak duruyor
      metaData: metaData,
    };
  }

  applyNoIndexParameter = (searchValues) => {
    if (this.props.location.search !== '') {
      const querystringValues = queryString.parse(this.props.location.search);
      const indexParameter = queryStringParameters.find(
        (x) => x.type === 'index',
      );
      const metaData = this.getMetaData(
        searchValues.taxons,
        searchValues.brands,
      );
      if (!querystringValues[indexParameter.key]) {
        if (!metaData.content) {
          querystringValues[indexParameter.key] = 'n';
          const qs = queryString.stringify(querystringValues, { sort: false });

          this.props.history.push({
            search: qs,
          });
        }
      } else {
        if (metaData.content) {
          delete querystringValues[indexParameter.key];
          const qs = queryString.stringify(querystringValues, { sort: false });
          this.props.history.push({
            search: qs,
          });
        }
      }
    }
  };

  componentDidMount() {
    var searchValues = this.getSearchValuesFromQueryString();

    this.props.getProductsById(
      searchValues.taxons.join(','),
      searchValues.brands.join(','),
      searchValues.keyword,
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const searchValues = this.getSearchValuesFromQueryString();
    if (this.props.taxonomies) {
      const taxons = searchValues.taxons.map((x) => parseInt(x));
      const taxonomies = this.props.taxonomies;
      const allTaxonomies = taxonomies.flatMap((t) => t.taxons);
      const newTaxons = allTaxonomies
        .filter(
          (at) =>
            taxons.includes(at.id) ||
            taxons.some((t) => at.redirectTaxonIds.some((rt) => rt === t)),
        )
        .map((at) => at.id);

      if (!arrayEquals(taxons.sort(), newTaxons.sort())) {
        const queryString = this.getQueryStringAndSlugFromSearchValues(
          newTaxons,
          searchValues.brands,
          searchValues.keyword,
          searchValues.sort.value,
        );
        const pathName = queryString.slug.length
          ? '/urunler/' + queryString.slug
          : '/urunler';
        window.location.href = pathName + '?' + queryString.queryString;
      }
    }

    var slug = this.props.match.params.category;
    let setQueryStringFromSlug =
      slug &&
      !searchValues.taxons.length &&
      !searchValues.brands.length &&
      !searchValues.keyword.length &&
      this.props.metadata.length &&
      this.props.taxonomies.length &&
      this.props.brands.length;

    if (setQueryStringFromSlug) {
      var queryStringFromSlug = this.getQueryStringFromSlug(slug);
      if (queryStringFromSlug?.queryString === undefined) {
        this.props.history.push('/');
        return;
      } else {
        this.props.history.push({
          pathname: '/urunler/' + slug,
          search: queryStringFromSlug.queryString,
        });
      }
    } else if (
      !this.haveSameValues(searchValues.taxons, prevState.taxon) ||
      !this.haveSameValues(searchValues.brands, prevState.brand) ||
      searchValues.keyword !== prevState.keyword
    ) {
      this.props.getProductsById(
        searchValues.taxons.join(','),
        searchValues.brands.join(','),
        searchValues.keyword,
      );
    }

    if (prevProps.history.location.search.startsWith('?t=redirect')) {
      var generatedQueryString = this.getQueryStringAndSlugFromSearchValues(
        searchValues.taxons,
        searchValues.brands,
        searchValues.keyword,
        searchValues.sort.value ?? this.state.productOrder.value,
      );
      var pathName = generatedQueryString.slug.length
        ? '/urunler/' + generatedQueryString.slug
        : '/urunler';
      this.props.history.push({
        pathname: pathName,
        search: generatedQueryString.queryString,
      });
    }
    if (searchValues.sort.value !== this.state.productOrder.value) {
      this.setState({
        productOrder: searchValues.sort,
      });
    }

    //TM-1873 i=n removed.
    //this.applyNoIndexParameter(searchValues);

    setPrerenderTimeout();
  }

  applyFilter = (filter, isBrand) => {
    const id = filter.target.value;
    const searchValues = this.getSearchValuesFromQueryString();

    if (filter.target.checked) {
      if (isBrand) {
        searchValues.brands.push(id);
      } else {
        searchValues.taxons.push(id);
      }
    } else {
      if (isBrand) {
        searchValues.brands = searchValues.brands.filter(
          (q) => q != filter.target.value,
        );
      } else {
        searchValues.taxons = searchValues.taxons.filter(
          (q) => q != filter.target.value,
        );
      }
    }

    var generatedQueryString = this.getQueryStringAndSlugFromSearchValues(
      searchValues.taxons,
      searchValues.brands,
      searchValues.keyword,
      this.state.productOrder.value,
    );

    var pathName = generatedQueryString.slug.length
      ? '/urunler/' + generatedQueryString.slug
      : '/urunler';
    this.props.history.push({
      pathname: pathName,
      search: generatedQueryString.queryString,
    });
  };

  removeFilter = (filter) => {
    const searchValues = this.getSearchValuesFromQueryString();
    searchValues.brands = searchValues.brands.filter(
      (b) => Number(b) !== Number(filter.id),
    );
    searchValues.taxons = searchValues.taxons.filter((t) => t != filter.id);
    searchValues.keyword =
      filter.type === 'keyword' ? '' : searchValues.keyword;

    var generatedQueryString = this.getQueryStringAndSlugFromSearchValues(
      searchValues.taxons,
      searchValues.brands,
      searchValues.keyword,
      searchValues.sort,
    );
    var pathName = generatedQueryString.slug.length
      ? '/urunler/' + generatedQueryString.slug
      : '/urunler';
    this.props.history.push({
      pathname: pathName,
      search: generatedQueryString.queryString,
    });
  };

  clearFilters = () => {
    this.setState({ firstFilteredTaxonomyId: 0, h1SeoTag: '' });
    this.props.history.push({
      pathname: '/urunler',
      search: '',
    });
  };

  orderProducts = (order) => {
    const searchValues = this.getSearchValuesFromQueryString();
    var generatedQueryString = this.getQueryStringAndSlugFromSearchValues(
      searchValues.taxons,
      searchValues.brands,
      searchValues.keyword,
      order.value,
    );

    var pathName = generatedQueryString.slug.length
      ? '/urunler/' + generatedQueryString.slug
      : '/urunler';
    this.setState(
      {
        productOrder: searchValues.sort,
      },
      () => {
        this.props.history.push({
          pathname: pathName,
          search: generatedQueryString.queryString,
        });
      },
    );
  };

  haveSameValues(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((a1) => arr2.includes(a1));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const filters = [];

    let getSearchValuesFromQueryString = () => {
      const querystringValues = queryString.parse(nextProps.location.search);
      let taxons = [];
      let brands = [];
      let keyword = '';
      for (let i = 0; i < queryStringParameters.length; i++) {
        let parameter = queryStringParameters[i];
        if (querystringValues[parameter.key]) {
          if (parameter.type === 'taxon') {
            taxons = [
              ...taxons,
              ...querystringValues[parameter.key].split(','),
            ];
          } else if (parameter.type === 'brand') {
            brands = [
              ...brands,
              ...querystringValues[parameter.key].split(','),
            ];
          } else if (parameter.type === 'keyword') {
            keyword = querystringValues[parameter.key];
          }
        }
      }

      return {
        taxons: Array.from(new Set(taxons)),
        brands: Array.from(new Set(brands)),
        keyword,
      };
    };

    let resetFirstFilteredTaxonomyIdIfNecessary = () => {
      if (
        (prevState.firstFilteredTaxonomyId > 0 && filters?.length == 0) ||
        !filters?.some(
          (t) => t.taxonomyId === prevState.firstFilteredTaxonomyId,
        )
      ) {
        prevState.firstFilteredTaxonomyId = 0;
      }
    };

    const searchValues = getSearchValuesFromQueryString();

    if (
      searchValues.taxons.length ||
      searchValues.brands.length ||
      searchValues.keyword
    ) {
      const filterT = searchValues.taxons;
      const filterB = searchValues.brands;

      if (searchValues.keyword) {
        filters.push({
          id: searchValues.keyword,
          apply: true,
          name: decodeURIComponent(searchValues.keyword),
          type: 'keyword',
        });
      }

      const filterArray = filterT.concat(filterB);

      if (filterArray.length) {
        nextProps.taxonomies.forEach((taxonomy) => {
          taxonomy.taxons.forEach((taxon) => {
            filterArray.forEach((filter) => {
              if (taxon.id == filter) {
                filters.push({
                  id: taxon.id,
                  taxonomyId: taxon.taxonomyId,
                  apply: true,
                  name: taxon.name,
                  type: 'taxon',
                });
                prevState.firstFilteredTaxonomyId =
                  prevState.firstFilteredTaxonomyId === 0
                    ? taxon.taxonomyId
                    : prevState.firstFilteredTaxonomyId;
              }
            });
          });
        });

        nextProps.brands.forEach((brand) => {
          filterArray.forEach((filter) => {
            if (brand.id == filter) {
              //number==string
              filters.push({
                id: brand.id,
                apply: true,
                name: brand.name,
                type: 'brand',
              });
            }
          });
        });

        // nextProps.brands.forEach((brand) => {
        //   filterArray.forEach((filter) => {
        //     if (brand.id == filter) {
        //       filters.push({
        //         id: brand.id,
        //         apply: true,
        //         name: brand.name,
        //         type: "brand",
        //       });
        //     }
        //   });
        // });
      }
    }
    resetFirstFilteredTaxonomyIdIfNecessary();

    return {
      ...prevState,
      products: nextProps.products,
      filters,
      keyword: searchValues.keyword,
      brand: searchValues.brands,
      taxon: searchValues.taxons,
      h1SeoTag: filters?.map((x) => x.name).join(' '),
    };
  }

  getCanonicalUrl() {
    var canonicalUrl = window.location.href;
    const queryParams = new URLSearchParams(this.props.location.search);

    if (queryParams.has('sort')) {
      queryParams.delete('sort');
      canonicalUrl =
        window.location.origin +
        this.props.location.pathname +
        '?' +
        queryParams.toString();
    }

    return canonicalUrl;
  }

  getTagCloud() {
    const { metadata, taxonomies } = this.props;
    const metaDataResult = [];
    var searchValues = this.getSearchValuesFromQueryString();

    var metadata2 = metadata;
    if (searchValues.brands.length) {
      metadata2 = metadata2.filter((m) =>
        searchValues.brands.includes(m.brand?.toString()),
      );
    }
    if (searchValues.taxons.length) {
      metadata2 = metadata2.filter((m) =>
        searchValues.taxons.includes(m.categories?.toString()),
      );
    }

    metadata2.forEach((meta) => {
      const query = {};

      if (meta.brand) {
        query.marka = meta.brand;
      }
      if (meta.categories.length) {
        meta.categories.forEach((category) => {
          const taxon = taxonomies.find((t) =>
            t.taxons.some((tx) => tx.id === category),
          );
          if (taxon) {
            if (taxon.id == 261) {
              query.seri = category;
            } else if (taxon.id == 149) {
              query.sinif = category;
            } else if (taxon.id == 151) {
              query.sinav = category;
            } else if (taxon.id == 152) {
              query.ders = category;
            } else if (taxon.id == 150) {
              query.tip = category;
            } else if (taxon.id == 146) {
              query.kategori = category;
            }
          }
        });
      }
      metaDataResult.push({
        name: meta.heading.replace(' ve Fiyatları', ''),
        link: `/urunler/${meta.slug}?${queryString.stringify(query)}`,
      });
    });

    return metaDataResult;
  }

  // stickySideBar = () => {
  //   //sidebar ölçüleri
  //   var sideBar = document.getElementById("sticky");
  //   var sideBarHeight = sideBar.offsetHeight;
  //   var sideBarTop = sideBar.offsetTop;
  //   var sideBarBottom = sideBarTop + sideBarHeight;

  //   //maximum scrollY
  //   var maxScrollY = sideBarHeight;

  //   //kullanıcının window height'ı
  //   var windowHeight = window.outerHeight;

  //   //windowun scrollY konumu
  //   var windowScrollY = window.pageYOffset;

  //   if (windowScrollY >  sideBarHeight ) {
  //     this.setState({ stickySideBar: false });
  //     // console.log("aşağı indi");
  //   } else if (windowScrollY < this.state.oldScrollY) {
  //     this.setState({ stickySideBar: true });
  //     // console.log("yukarı çıktı");
  //   }

  //   console.log(windowScrollY);
  //   console.log(this.state.stickySideBar);
  //   this.setState({ oldScrollY: windowScrollY });
  // };

  render() {
    // window.addEventListener("scroll", this.stickySideBar);
    const filteredProducts = this.state.products;
    let orderedProducts = [];
    const productsToOrder =
      this.state.productOrder.value === orderList[3].value
        ? filteredProducts.filter(product => product.isDigital)
        : filteredProducts.filter(product => !product.isDigital);

    switch (this.state.productOrder.value) {
      case orderList[1].value:
        orderedProducts = productsToOrder.filter(product => product.taxons.includes(37));
        break;
      case orderList[2].value:
        orderedProducts = productsToOrder.sort((a, b) => b.isNew - a.isNew);
        break;
      case orderList[3].value:
        orderedProducts = productsToOrder;
        break;
        case orderList[4].value:
          orderedProducts = productsToOrder
            .filter(product => product.reviewCount > 0)
            .sort((a, b) => b.reviewCount - a.reviewCount);
          break;
      // case orderList[5].value:
      //   orderedProducts = productsToOrder
      //     .concat()
      //     .sort((a, b) => Number(a.class) - Number(b.class));
      //   break;
      // case orderList[6].value:
      //   orderedProducts = productsToOrder
      //     .concat()
      //     .sort((a, b) => Number(b.class) - Number(a.class));
      //   break;
      case orderList[5].value:
        orderedProducts = productsToOrder
          .concat()
          .sort(
            (a, b) => (a.total || 0).toFixed(2) - (b.total || 0).toFixed(2)
          );
        break;
      case orderList[6].value:
        orderedProducts = productsToOrder
          .concat()
          .sort(
            (a, b) => (b.total || 0).toFixed(2) - (a.total || 0).toFixed(2)
          );
        break;
      default:
        orderedProducts = productsToOrder;
        break;
    }
    var searchValues = this.getSearchValuesFromQueryString();
    const metaData = this.getMetaData(searchValues.taxons, searchValues.brands);
    var canonicalUrl = this.getCanonicalUrl();
    var pageCategory = metaData.slug.length ? metaData.slug : 'Tüm Ürünler';
    var contentIds = filteredProducts.map((f) => f.id).join("','");
    var contentName = metaData.title.length ? metaData.title : 'Tüm Ürünler';
    const { taxonomies } = this.props;
    const GA4Products = [];

    orderedProducts.forEach((product, index) => {
      const taxonIds = new Set(
        product.taxons.filter((element) => typeof element === 'number'),
      );

      const productTaxons = taxonomies
        .filter((t) => t.taxons.some((tx) => taxonIds.has(tx.id)))
        .flatMap((t) => {
          return t.taxons.filter((taxon) => taxonIds.has(taxon.id));
        });
      const productTopTaxonId = productTaxons?.[0]?.taxonomyId;
      const productTopTaxon = taxonomies?.filter(
        (t) => t.id == productTopTaxonId,
      );
      GA4Products.push({
        item_id: product.id,
        item_name: JSON.stringify(product.name),
        currency: 'TRY',
        index: index,
        item_category: JSON.stringify(productTaxons?.[0]?.name),
        item_category2: JSON.stringify(productTaxons?.[1]?.name),
        item_category3: JSON.stringify(productTaxons?.[2]?.name),
        item_category4: JSON.stringify(productTaxons?.[3]?.name),
        item_category5: JSON.stringify(productTaxons?.[4]?.name),
        item_variant: JSON.stringify(
          productTaxons?.[0]?.name ?? product.category,
        ),
        item_list_id: productTopTaxon?.[0]?.id,
        item_list_name: productTopTaxon?.[0]?.description,
        price: Number((product.total || 0).toFixed(2)),
        discount: 0,
      });
    });

    return (
      <>
        <Helmet>
          <title>{metaData.title}</title>
          {metaData.description ? (
            <meta name="description" content={metaData.description} />
          ) : null}
          <link rel="canonical" href={canonicalUrl} />

          {contentIds && contentIds.length && (
            <script>
              {`gtag('event', 'page_view', {
              'send_to':'AW-596855146',
              'ecomm_pagetype': 'category',
              'ecomm_category':'${pageCategory}'
              });

              fbq('track', 'ViewCategory', {
                        content_name: "${contentName}",
                        content_category: "${pageCategory}",
                        content_ids: ["${contentIds}"],
                        content_type: "product"
                });`}
            </script>
          )}
          <script>
            {`
                dataLayer.push({ecommerce: null });
                dataLayer.push({
                  event: "view_item_list",
                    'ecommerce': {
                      'items': ${JSON.stringify(GA4Products)}
                    }});
            `}
          </script>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-12 p-xs-0">
              <Breadcrumb items={this.state.breadcrumbs} />
            </div>

            {searchValues.keyword !== '' && orderedProducts.length === 0 ? (
              <div className="text-center col-12">
                <div style={{ marginBottom: '10rem', marginTop: '2rem' }}>
                  {this.props.allProductsLoaded === true ? (
                    <>
                      <p className="fs-18">
                        <span className="font-weight-500">
                          "{searchValues.keyword}"
                        </span>{' '}
                        ile ilgili sonuç bulunamamıştır.
                      </p>
                      <img
                        src={require('../../assets/images/icons/sad.png')}
                        alt=""
                      />
                    </>
                  ) : (
                    <p className="fs-18">
                      <span className="font-weight-500">
                        Sonuçlar yükleniyor...
                      </span>
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="col-4 col-lg-3 brand-show-filter">
                  <StickyBox>
                    <SideBar
                      stickyScrollTop={this.state.stickyScrollTop}
                      stickySideBar={this.state.stickySideBar}
                      isShowMarkalar={this.state.isShowMarkalar}
                      markaFilterChange={() =>
                        this.setState({
                          isShowMarkalar: !this.state.isShowMarkalar,
                        })
                      }
                      filters={this.state.filters}
                      taxonomies={this.props.taxonomies}
                      brands={this.props.brands}
                      allProducts={this.props.allProducts}
                      applyFilter={this.applyFilter}
                      filteredProducts={filteredProducts}
                      firstFilteredTaxonomyId={
                        this.state.firstFilteredTaxonomyId
                      }
                    />
                  </StickyBox>
                </div>
                <div className="col-8 col-lg-9 p-0 brand-show-list">
                  <ClassList
                    filters={this.state.filters}
                    taxonomies={this.props.taxonomies}
                    applyFilter={this.applyFilter}
                  />

                  <div className="row brand-show-mobile-filter">
                    <div className="col-6 p-0 d-flex">
                      <button
                        onClick={() => this.setState({ showSortModal: true })}
                        className="btn btn-link text-decoration-none w-100 text-capitalize btn-border-right"
                      >
                        <div className="filter-icon-box">
                          <Icon className="mr-2" icon={'iMobileSort'} />
                          <div
                            className={`red-circle ${
                              this.state.productOrder === orderList[0]
                                ? null
                                : 'active'
                            }`}
                          />
                        </div>
                        Sırala
                      </button>
                    </div>
                    <div className="col-6 p-0 d-flex">
                      <button
                        onClick={() => this.setState({ showFilterModal: true })}
                        className="btn btn-link text-decoration-none w-100 text-capitalize"
                      >
                        <div className="filter-icon-box">
                          <Icon className="mr-2" icon={'iMobileFilter'} />
                          <div
                            className={`red-circle ${
                              this.state.filters.length > 0 ? 'active' : null
                            }`}
                          />
                        </div>
                        Filtrele
                      </button>
                    </div>
                  </div>

                  <Filters
                    items={this.state.filters}
                    onRemove={this.removeFilter}
                    onClearAll={this.clearFilters}
                  />

                  <div className="d-flex align-items-center">
                    <OrderList
                      selectSort={this.selectSort}
                      isSearchable={false}
                      onChange={this.orderProducts}
                      options={orderList}
                      defaultValue={this.state.productOrder}
                      value={this.state.productOrder}
                      styles={customStyles}
                    />
                    <ViewList
                      showMoreList={this.state.showMoreList}
                      list4={this.list4}
                      list3={this.list3}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center w-100 my-4">
                    <h1 className="mb-0 filter-result-title">
                      "
                      {this.state.h1SeoTag.length
                        ? this.state.h1SeoTag
                        : this.state.breadcrumbs[1].title}
                      "
                    </h1>
                    &nbsp;
                    <span className="filter-result-detail">
                      için {orderedProducts.length} ürün listeleniyor
                    </span>
                    {/* <Select
                    isSearchable={false}
                    onChange={this.orderProducts}
                    options={orderList}
                    defaultValue={this.state.productOrder}
                    value={this.state.productOrder}
                    className="brand-show-filter-select"
                    styles={customStyles}
                  /> */}
                  </div>
                  {orderedProducts.length === 0 &&
                  !(this.props.allProductsLoaded === true) ? (
                    <div className="d-flex flex-wrap product-group">
                      <div className="card product-card">
                        <Skeleton variant="rect" width={292} height={364} />
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={125} />
                        <Skeleton variant="text" width={250} />
                      </div>
                      <div className="card product-card">
                        <Skeleton variant="rect" width={292} height={364} />
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={125} />
                        <Skeleton variant="text" width={250} />
                      </div>
                      <div className="card product-card">
                        <Skeleton variant="rect" width={292} height={364} />
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={125} />
                        <Skeleton variant="text" width={250} />
                      </div>
                    </div>
                  ) : (
                    <ProductList
                      items={orderedProducts}
                      showMoreList={this.state.showMoreList}
                    />
                  )}

                  {metaData.heading ? (
                    <h1 className="mb-4">{metaData.heading}</h1>
                  ) : null}
                  {metaData.content ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: metaData.content }}
                    ></div>
                  ) : null}
                  <div className="tag-cloud">
                    {this.getTagCloud().map((tag, i) => (
                      <Link to={tag.link}>{tag.name}</Link>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <FilterModal
          show={this.state.showFilterModal}
          filters={this.state.filters}
          taxonomies={this.props.taxonomies}
          // brands={this.props.brands}
          applyFilter={this.applyFilter}
          clearFilters={this.clearFilters}
          removeFilter={this.removeFilter}
          onCloseClick={() => this.setState({ showFilterModal: false })}
        />

        <SortModal
          value={this.state.productOrder}
          selectSort={this.selectSort}
          show={this.state.showSortModal}
          order={this.orderProducts}
          onCloseClick={() => this.setState({ showSortModal: false })}
        />
      </>
    );
  }
}

Products.propTypes = {};

const mapStateToProps = ({ AccountReducer, ProductReducer }) => ({
  user: AccountReducer.user,
  products: ProductReducer.products,
  brands: ProductReducer.brands,
  taxonomies: ProductReducer.taxonomies,
  metadata: ProductReducer.metadata,
  allProductsLoaded: ProductReducer.allProductsLoaded,
  allProducts: ProductReducer.allProducts,
});

const mapDispatchToProps = {
  getProductsById: actions.getProductsById,
  search: actions.search,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
